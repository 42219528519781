* {
  -webkit-font-smoothing: antialiased !important;
}

.MuiInputLabel-root {
  color: var(--label) !important;

  &.MuiFormLabel-filled,
  &.Mui-focused {
    font-weight: bold !important;
  }
}

.MuiOutlinedInput-notchedOutline {
  border-color: var(--label-border) !important;
}

.MuiFormHelperText-filled,
.MuiFormHelperText-contained,
.error-helper {
  margin-left: 0 !important;
  color: var(--error) !important;
  font-weight: bold !important;
}

.Mui-error {
  .MuiOutlinedInput-notchedOutline {
    border-color: var(--error) !important;
  }
}

.MuiLoadingButton-root:disabled {
  color: #a49bbe !important;
  background: #ebe3ff !important;
  cursor: not-allowed !important;
}

.dark-mode {
  .MuiMenu-list {
    border-radius: 6px;
    background-color: #2b2639;
  }

  .MuiMenuItem-gutters.Mui-selected {
    background-color: #a49bbe !important;
    padding: 12px 16px;
  }
}

// Color Picker changes to border radius 6
.circle-picker {
  div[title] {
    border-radius: 6px !important;
  }
}
