:root {
  --container-width: 1281px;
  --ui-dark-colors-secondary-dark-mode: #2b2639;
}

html,
body {
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2b2639;
  font-size: 15px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* #root {
  height: 100%;
} */

/* width */

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #a49bbe;
  border: 1px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background: #d7d0e8;
}

::-webkit-scrollbar-button {
  height: 12px !important;
}
